<template>
    <main id="content" class="content">
        <section
                class="vc_row py-5 py-md-0 bg-cover bg-center vh-80 d-flex flex-wrap align-items-center"
                data-slideshow-bg="true"
                data-slideshow-options='{"delay":2800, "effect": "slide", "imageArray": ["https://images.unsplash.com/photo-1612832164313-ac0d7e07b5ce?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80", "https://images.unsplash.com/photo-1612831198717-1e71a0d5e2ad?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80", "https://images.unsplash.com/photo-1573164574230-db1d5e960238?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80", "https://images.unsplash.com/photo-1503899939428-0db7bab17e19?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1353&q=80"] }'>

            <span class="row-bg-loader"></span>

            <div class="liquid-row-overlay bg-fade-dark-05"></div>

            <div class="container">
                <div class="row d-flex flex-wrap align-items-center">

                    <div class="lqd-column col-md-7 col-xs-12 mb-7 mb-md-0"
                         data-custom-animations="true"
                         data-ca-options='{ "triggerHandler":"inview", "animationTarget":"all-childs", "duration":"1200", "delay":"150", "easing":"easeOutQuint", "direction":"forward", "initValues":{"translateY":60, "opacity":0}, "animations":{"translateY":0, "opacity":1} }'>

                        <h2 class="text-white mt-0 mb-30"
                                data-split-text="true"
                                data-split-options='{"type":"lines"}'
                                data-fittext="true"
                                data-fittext-options='{"compressor": 0.75, "maxFontSize": 52}'>Think <span class="text-purple-20">it</span>, do <span class="text-purple-20">it</span>
                        </h2>

                        <p class="font-size-16 lh-175 text-fade-white-07 pr-md-9 mr-md-9 mb-80" data-ca-options='{"triggerHandler":"inview","animationTarget":".split-inner","duration":"1200","delay":"35","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":20,"rotateZ":9,"opacity":0},"animations":{"translateY":0,"rotateZ":0,"opacity":1}}'
                           data-split-options='{"type":"chars, words"}'
                           data-text-rotator="true">
                            <span class="ld-fh-txt">
                                <span class="txt-rotate-keywords">
                                    <span class="keyword active">Business is not complete until there is sales. Our team of digital marketing experts and strategists are equipped to <br> give you cutting edge insights into markets and improve your turnover.</span>
                                    <span class="keyword">We are in the business of mapping customer journeys for businesses, start-ups and established organizations. <br> Our core mandate is to cut out redundancies and improve your business performance using technology</span>
                                    <span class="keyword">Our partnership with top retail chains and supermarkets in Lagos makes us your institution of choice in distributing <br>your goods to the markets that matter. We dont just stock shelves, we stock the right shelves. Let's grow your biusiness with the<br> right distribution into locations where your goods are most in demand. We are also partners with reputable logistics companies to deliver value<br> to you at amazing prices.!</span>
                                    <span class="keyword">From ideation to execution, we've got just the right minds to improve your operations and your business.</span>
                                </span><!-- /.txt-rotate-keywords -->
                            </span>
                        </p>

                        <a href="/services" class="btn btn-purple text-uppercase circle btn-bordered border-thin font-size-14 font-weight-semibold">
								<span>
									<span class="btn-txt"> &nbsp; Explore Our Services&nbsp;</span>
								</span>
                        </a>

                    </div><!-- /.col-md-7 -->

                </div><!-- /.row -->
            </div><!-- /.container -->

        </section>

        <section id="about" class="vc_row pt-100 pb-200 bg-gray">
            <div class="container">
                <div class="row d-flex flex-wrap align-items-center">

                    <div class="lqd-column col-md-6" data-custom-animations="true" data-ca-options='{"triggerHandler":"inview","animationTarget":"all-childs","duration":"1200","delay":"150","easing":"easeOutQuint","direction":"forward","initValues":{"translateY":31,"translateZ":-108,"opacity":0},"animations":{"translateY":0,"translateZ":0,"opacity":1}}'>
                        <h6 class="font-size-12 text-uppercase ltr-sp-2 text-purple">About Us</h6>

                        <hr class="w-10 ml-0 mr-0">
                        <h4
                                class="mt-2 mb-3 lh-1 font-weight-light"
                                data-fittext="true"
                                data-fittext-options='{ "compressor": 0.65, "maxFontSize": 62, "minFontSize": 36 }'
                                data-split-text="true"
                                data-split-options='{"type":"lines"}'
                        >
                            We <span class="text-primary">engineer</span>,<br>
                            solutions
                        </h4>

                        <p class="pr-md-7 mr-md-7 mb-60 blockquote">
                            We provide enterprises with innovative technology for small to medium and large enterprises.
                        </p>
                        <a href="/about" class="btn text-uppercase circle btn-bordered border-thin font-size-14 font-weight-semibold">
                            <span>
                                <span class="btn-txt"> &nbsp;Read more&nbsp;</span>
                            </span>
                        </a>
                    </div><!-- /.col-md-6 -->

                    <div class="lqd-column col-md-5 col-md-offset-1">

                        <div class="lqd-parallax-images">

                            <div class="liquid-img-group-single" data-shadow-style="4" data-inview="true" data-animate-shadow="true">
                                <div class="liquid-img-group-img-container">
                                    <div class="liquid-img-container-inner"
                                         data-parallax="true"
                                         data-parallax-from='{"translateY":78}'
                                         data-parallax-to='{"translateY":-78}'
                                         data-parallax-options='{"overflowHidden":false,"easing":"linear"}'
                                    >
                                        <figure>
                                            <img src="/assets/img/actual/guy2.jpg" alt="Good Design" />
                                        </figure>
                                    </div><!-- /.liquid-img-container-inner -->
                                </div><!-- /.liquid-img-group-img-container -->
                            </div><!-- /.liquid-img-group-single -->

                            <div class="liquid-img-group-single w-75" data-shadow-style="4" data-inview="true" data-animate-shadow="true">
                                <div class="liquid-img-group-img-container">
                                    <div class="liquid-img-container-inner"
                                         data-parallax="true"
                                         data-parallax-from='{"translateY":107}'
                                         data-parallax-to='{"translateY":-134}'
                                         data-parallax-options='{"overflowHidden":false,"easing":"linear"}'
                                    >
                                        <figure>
                                            <img src="/assets/img/actual/web-report.jpg" alt="Good Design" />
                                        </figure>
                                    </div><!-- /.liquid-img-container-inner -->
                                </div><!-- /.liquid-img-group-img-container -->
                            </div><!-- /.liquid-img-group-single -->

                        </div><!-- /.lqd-parallax-images -->

                    </div><!-- /.col-md-5 col-md-offset-1 -->

                </div><!-- /.row -->
            </div><!-- /.container -->
        </section>

        <section class="vc_row pt-90 pb-90"
                 style="background-image: url(/assets/demo/bg/bg-77.svg); background-position: 500% 0%;">
            <div class="container">

                <div class="row d-flex flex-wrap align-items-center">

                    <div
                            class="lqd-column col-md-5 mb-30"
                            data-custom-animations="true"
                            data-ca-options='{"triggerHandler":"inview", "animationTarget":"all-childs", "duration":"1600", "delay":"160", "easing":"easeOutQuint", "direction":"forward", "initValues":{"translateY":30, "opacity":0}, "animations":{"translateY":0, "opacity":1}}'>

                        <header class="fancy-title pr-md-4">
                            <h6 class="font-size-12 text-uppercase ltr-sp-2 text-purple">What we do</h6>

                            <hr class="w-10 m-0">
                            <h2>Core Services</h2>
                        </header>

                        <p class="font-size-18 lh-15 mb-55 blockquote">
                            In solving a <strong>reasonable problem,</strong> reasonable effort too is required; plus a qualified
                            <strong>engineering company</strong> to partner with.
                        </p>

                        <a
                                href="/services"
                                class="btn btn-grey text-uppercase btn-md circle font-weight-bold px-2"
                                data-localscroll="true" data-localscroll-options='{"scrollBelowSection":true}'>
								<span>
									<span class="btn-grey"></span>
									<span class="btn-txt">Services</span>
									<span class="btn-grey"></span>
								</span>
                        </a>

                    </div><!-- /.lqd-column col-md-5 -->

                    <div
                            class="lqd-column col-md-3 col-md-offset-1"
                    >

                        <div
                                class="lqd-column-inner"
                                data-parallax="true"
                                data-parallax-from='{"translateY":-30}'
                                data-parallax-to='{"translateY":120}'
                                data-parallax-options='{"duration":"2000", "easing":"linear", "reverse":true, "triggerHook":"onEnter", "overflowHidden": false}'>

                            <div
                                    class="iconbox text-left iconbox-round iconbox-lg iconbox-filled iconbox-filled iconbox-filled-hover iconbox-icon-image iconbox-shadow pt-50 pb-40">
                                <div class="iconbox-icon-wrap">
										<span class="iconbox-icon-container mb-35">
											<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48"
                                                 height="36.75" viewBox="0 0 48 36.75">
												<defs>
													<filter id="filter-seo-icon-7" x="0" y="0.625" width="48" height="36.75"
                                                            filterUnits="userSpaceOnUse">
														<feImage preserveAspectRatio="none" x="0" y="0.625" width="48" height="36.75" result="image"
                                                                 xlink:href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNDgiIGhlaWdodD0iMzYuNzUiIHZpZXdCb3g9IjAgMCA0OCAzNi43NSI+CiAgPGRlZnM+CiAgICA8c3R5bGU+CiAgICAgIC5jbHMtMSB7CiAgICAgICAgZmlsbDogdXJsKCNsaW5lYXItZ3JhZGllbnQpOwogICAgICB9CiAgICA8L3N0eWxlPgogICAgPGxpbmVhckdyYWRpZW50IGlkPSJsaW5lYXItZ3JhZGllbnQiIHkxPSIxOC4zNzUiIHgyPSI0OCIgeTI9IjE4LjM3NSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgogICAgICA8c3RvcCBvZmZzZXQ9IjAiIHN0b3AtY29sb3I9IiNmZTUwNmMiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjZmM2YTJhIi8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KICA8cmVjdCBjbGFzcz0iY2xzLTEiIHdpZHRoPSI0OCIgaGVpZ2h0PSIzNi43NSIvPgo8L3N2Zz4K"/>
														<feComposite result="composite" operator="in" in2="SourceGraphic"/>
														<feBlend result="blend" in2="SourceGraphic"/>
													</filter>
												</defs>
												<path filter="url(#filter-seo-icon-7)"
                                                      d="M16.781,3.438H48V37.375H0V3.438H8.625V0.625h8.156V3.438h0ZM45.188,34.563V14.781H40.781a6.936,6.936,0,0,1,.656,1.266,12.542,12.542,0,0,1,.469,1.406q0.187,0.7.328,1.453a8.139,8.139,0,0,1,.141,1.5,11,11,0,0,1-.891,4.406,11.446,11.446,0,0,1-6.047,6.047,10.984,10.984,0,0,1-4.406.891,10.645,10.645,0,0,1-4.359-.891,11.635,11.635,0,0,1-3.562-2.437,11.482,11.482,0,0,1-2.437-3.609,10.972,10.972,0,0,1-.891-4.406,12,12,0,0,1,.094-1.5,10.585,10.585,0,0,1,.281-1.453,8.381,8.381,0,0,1,.516-1.406,9.473,9.473,0,0,1,.7-1.266H2.813V34.563H45.188ZM39.563,20.406a7.951,7.951,0,0,0-.7-3.328,9.009,9.009,0,0,0-1.828-2.672,8.347,8.347,0,0,0-2.672-1.781,8.454,8.454,0,0,0-3.328-.656,8.216,8.216,0,0,0-3.234.656,8.916,8.916,0,0,0-2.719,1.781,7.95,7.95,0,0,0-1.828,2.672,8.768,8.768,0,0,0,0,6.656,7.949,7.949,0,0,0,1.828,2.672A8.889,8.889,0,0,0,27.8,28.188a8.189,8.189,0,0,0,3.234.656,8.427,8.427,0,0,0,3.328-.656,8.323,8.323,0,0,0,2.672-1.781,9.009,9.009,0,0,0,1.828-2.672,7.947,7.947,0,0,0,.7-3.328h0Zm-1.125-8.437h6.75V6.25H2.813v5.719H23.625A10.4,10.4,0,0,1,25.266,10.8a15.889,15.889,0,0,1,1.781-.891,9.251,9.251,0,0,1,1.922-.562,11.54,11.54,0,0,1,2.062-.187,11.312,11.312,0,0,1,2.109.188,10.571,10.571,0,0,1,1.922.563,12.033,12.033,0,0,1,1.781.891,11.792,11.792,0,0,1,1.594,1.172h0Z"
                                                      transform="translate(0 -0.625)"/>
											</svg>
										</span>
                                </div><!-- /.iconbox-icon-wrap -->
                                <div class="contents">
                                    <h3>Web Development</h3>
                                    <p class="font-size-16 lh-165">We capture the best browser experiences whilst solving complex business processes from the web.</p>
                                </div><!-- /.contents -->
                            </div><!-- /.iconbox -->

                            <div
                                    class="iconbox text-left iconbox-round iconbox-lg iconbox-filled iconbox-filled iconbox-filled-hover iconbox-icon-image iconbox-shadow pt-50 pb-40">
                                <div class="iconbox-icon-wrap">
										<span class="iconbox-icon-container mb-35">
											<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48"
                                                 height="42.375" viewBox="0 0 48 42.375">
												<defs>
													<filter id="filter-seo-icon-6" x="0" y="0.813" width="48" height="42.375"
                                                            filterUnits="userSpaceOnUse">
														<feImage preserveAspectRatio="none" x="0" y="0.8125" width="48" height="42.375"
                                                                 result="image"
                                                                 xlink:href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNDgiIGhlaWdodD0iNDIuMzc1IiB2aWV3Qm94PSIwIDAgNDggNDIuMzc1Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiB1cmwoI2xpbmVhci1ncmFkaWVudCk7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImxpbmVhci1ncmFkaWVudCIgeTE9IjIxLjE4OCIgeDI9IjQ4IiB5Mj0iMjEuMTg4IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CiAgICAgIDxzdG9wIG9mZnNldD0iMCIgc3RvcC1jb2xvcj0iI2ZlNTA2YyIvPgogICAgICA8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNmYzZhMmEiLz4KICAgIDwvbGluZWFyR3JhZGllbnQ+CiAgPC9kZWZzPgogIDxyZWN0IGNsYXNzPSJjbHMtMSIgd2lkdGg9IjQ4IiBoZWlnaHQ9IjQyLjM3NSIvPgo8L3N2Zz4K"/>
														<feComposite result="composite" operator="in" in2="SourceGraphic"/>
														<feBlend result="blend" in2="SourceGraphic"/>
													</filter>
												</defs>
												<path filter="url(#filter-seo-icon-6)"
                                                      d="M0,0.813H48V43.188H0V0.813H0ZM45.188,9.25V3.625H37.781L44.438,9.25h0.75ZM24.656,17.781h8.25l6.656-5.625h-8.25l-6.656,5.625h0ZM19.219,9.25h8.25L20.813,3.625h-8.25L19.219,9.25h0ZM27,12.156H18.75l-6.656,5.625h8.25L27,12.156h0ZM31.781,9.25h8.25L33.375,3.625h-8.25L31.781,9.25h0ZM2.813,3.625V9.25h12L8.156,3.625H2.813Zm0,8.531v5.625H7.688l6.656-5.625H2.813ZM45.188,40.375V20.594H2.813V40.375H45.188Zm0-22.594V12.156H43.969l-6.656,5.625h7.875Z"
                                                      transform="translate(0 -0.813)"/>
											</svg>
										</span>
                                </div><!-- /.iconbox-icon-wrap -->
                                <div class="contents">
                                    <h3>Web Design</h3>
                                    <p class="font-size-16 lh-165">Convert your websites from static/animated prototypes to actual websites your users can interact with.</p>
                                </div><!-- /.contents -->
                            </div><!-- /.iconbox -->

                        </div><!-- /.lqd-column-inner -->

                    </div><!-- /.lqd-column col-md-3 -->

                    <div class="lqd-column col-md-3">

                        <div
                                class="lqd-column-inner"
                                data-parallax="true"
                                data-parallax-from='{"translateY":50}'
                                data-parallax-to='{"translateY":-130}'
                                data-parallax-options='{"duration":"2000", "easing":"linear", "reverse":true, "triggerHook":"onEnter", "overflowHidden": false}'>

                            <div
                                    class="iconbox text-left iconbox-round iconbox-lg iconbox-filled iconbox-filled iconbox-filled-hover iconbox-icon-image iconbox-shadow pt-50 pb-40">
                                <div class="iconbox-icon-wrap">
										<span class="iconbox-icon-container mb-35">
											<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="42.375"
                                                 height="45.188" viewBox="0 0 42.375 45.188">
												<defs>
													<filter id="filter-seo-icon-4" x="0.813" y="0" width="42.375" height="45.188"
                                                            filterUnits="userSpaceOnUse">
														<feImage preserveAspectRatio="none" x="0.8125" y="0" width="42.375" height="45.188"
                                                                 result="image"
                                                                 xlink:href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNDIuMzc1IiBoZWlnaHQ9IjQ1LjE4OCIgdmlld0JveD0iMCAwIDQyLjM3NSA0NS4xODgiPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6IHVybCgjbGluZWFyLWdyYWRpZW50KTsKICAgICAgfQogICAgPC9zdHlsZT4KICAgIDxsaW5lYXJHcmFkaWVudCBpZD0ibGluZWFyLWdyYWRpZW50IiB5MT0iMjIuNTk0IiB4Mj0iNDIuMzc1IiB5Mj0iMjIuNTk0IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CiAgICAgIDxzdG9wIG9mZnNldD0iMCIgc3RvcC1jb2xvcj0iI2ZlNTA2YyIvPgogICAgICA8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNmYzZhMmEiLz4KICAgIDwvbGluZWFyR3JhZGllbnQ+CiAgPC9kZWZzPgogIDxyZWN0IGNsYXNzPSJjbHMtMSIgd2lkdGg9IjQyLjM3NSIgaGVpZ2h0PSI0NS4xODgiLz4KPC9zdmc+Cg=="/>
														<feComposite result="composite" operator="in" in2="SourceGraphic"/>
														<feBlend result="blend" in2="SourceGraphic"/>
													</filter>
												</defs>
												<path filter="url(#filter-seo-icon-4)"
                                                      d="M26.313,28.406l-8.531-5.719L9.25,28.406V0H26.313V28.406h0Zm-14.25-5.25,5.719-3.937L23.5,23.156V2.813H12.063V23.156h0ZM43.188,2.813H29.031V5.625H40.375v36.75H3.625V5.625H6.438V2.813H0.813V45.188H43.188V2.813h0Z"
                                                      transform="translate(-0.813)"/>
											</svg>
										</span>
                                </div><!-- /.iconbox-icon-wrap -->
                                <div class="contents">
                                    <h3>API Development</h3>
                                    <p class="font-size-16 lh-165">
                                        Strictly communicating business logic via several endpoints/servers.
                                    </p>
                                </div><!-- /.contents -->
                            </div>


                            <div
                                    class="iconbox text-left iconbox-round iconbox-lg iconbox-filled iconbox-filled iconbox-filled-hover iconbox-icon-image iconbox-shadow pt-50 pb-40">
                                <div class="iconbox-icon-wrap">
										<span class="iconbox-icon-container mb-35">
											<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="31.219"
                                                 height="48" viewBox="0 0 31.219 48">
												<defs>
													<filter id="filter-seo-icon-5" x="0.344" y="0" width="31.219" height="48"
                                                            filterUnits="userSpaceOnUse">
														<feImage preserveAspectRatio="none" x="0.34375" y="0" width="31.219" height="48"
                                                                 result="image"
                                                                 xlink:href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzEuMjE5IiBoZWlnaHQ9IjQ4IiB2aWV3Qm94PSIwIDAgMzEuMjE5IDQ4Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiB1cmwoI2xpbmVhci1ncmFkaWVudCk7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImxpbmVhci1ncmFkaWVudCIgeTE9IjI0IiB4Mj0iMzEuMjE5IiB5Mj0iMjQiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KICAgICAgPHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjZmU1MDZjIi8+CiAgICAgIDxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI2ZjNmEyYSIvPgogICAgPC9saW5lYXJHcmFkaWVudD4KICA8L2RlZnM+CiAgPHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iMzEuMjE5IiBoZWlnaHQ9IjQ4Ii8+Cjwvc3ZnPgo="/>
														<feComposite result="composite" operator="in" in2="SourceGraphic"/>
														<feBlend result="blend" in2="SourceGraphic"/>
													</filter>
												</defs>
												<path filter="url(#filter-seo-icon-5)"
                                                      d="M27.25,0a4.281,4.281,0,0,1,1.641.328,4.134,4.134,0,0,1,1.359.891,4.8,4.8,0,0,1,.938,1.359,3.026,3.026,0,0,1,.281,1.641l0.094,39.562a7.368,7.368,0,0,1-.375,1.641,3.156,3.156,0,0,1-.937,1.359,6.025,6.025,0,0,1-1.359.891A4.1,4.1,0,0,1,27.156,48H4.75a4.1,4.1,0,0,1-1.734-.328,5.993,5.993,0,0,1-1.359-.891,3.143,3.143,0,0,1-.937-1.359,7.287,7.287,0,0,1-.375-1.641L0.438,4.219A3,3,0,0,1,.719,2.578a4.77,4.77,0,0,1,.938-1.359A4.119,4.119,0,0,1,3.016.328,4.276,4.276,0,0,1,4.656,0H27.25ZM4.656,2.813a1.8,1.8,0,0,0-.562.094,0.754,0.754,0,0,0-.422.328,4.2,4.2,0,0,1-.328.469,0.514,0.514,0,0,0-.094.516L3.156,8.438H28.75L28.656,4.219A0.521,0.521,0,0,0,28.563,3.7a4,4,0,0,1-.328-0.469,0.754,0.754,0,0,0-.422-0.328,1.8,1.8,0,0,0-.562-0.094H4.656ZM28.75,11.25H3.156L3.25,33.844H28.656L28.75,11.25h0ZM27.156,45.188a1.632,1.632,0,0,0,.656-0.094,1.744,1.744,0,0,0,.422-0.328,1.436,1.436,0,0,0,.328-0.469c0.061-.155.125-0.328,0.188-0.516L28.656,36.75H3.25L3.156,43.781c0.062,0.188.125,0.36,0.188,0.516a1.392,1.392,0,0,0,.328.469,1.688,1.688,0,0,0,.422.328,1.622,1.622,0,0,0,.656.094H27.156Zm-9.75-4.219a3.6,3.6,0,0,1-.141-0.563,0.752,0.752,0,0,0-.281-0.469,1.923,1.923,0,0,0-.469-0.281,1.691,1.691,0,0,0-.609-0.094,2.045,2.045,0,0,0-.516.094,0.86,0.86,0,0,0-.469.281,3.142,3.142,0,0,0-.281.469,0.709,0.709,0,0,0-.047.563,0.592,0.592,0,0,0,.047.516,4.792,4.792,0,0,0,.281.422,1.112,1.112,0,0,0,.469.328,1.417,1.417,0,0,0,.516.141,1.177,1.177,0,0,0,.609-0.141,4.28,4.28,0,0,0,.469-0.328,0.733,0.733,0,0,0,.281-0.422,4.218,4.218,0,0,1,.141-0.516h0Z"
                                                      transform="translate(-0.344)"/>
											</svg>
										</span>
                                </div><!-- /.iconbox-icon-wrap -->
                                <div class="contents">
                                    <h3>Mobile Development</h3>
                                    <p class="font-size-16 lh-165">We fuse together fantastic mobile applications built to communicate your business logic and visual elements.</p>
                                </div><!-- /.contents -->
                            </div><!-- /.iconbox -->

                            <!-- /.iconbox -->


                        </div><!-- /.lqd-column-inner -->

                    </div><!-- /.lqd-column col-md-3 -->

                </div><!-- /.row -->


            </div><!-- /.container -->
        </section>

        <section class="vc_row pt-120 pb-90 bg-dark-blue">
            <div class="container">
                <div class="row d-flex flex-wrap align-items-center">

                    <div class="lqd-column col-md-5 col-xs-12">

                        <div class="liquid-img-group-container">
                            <div class="liquid-img-group-inner">
                                <div class="liquid-img-group-single" data-roundness="6" data-reveal="true" data-reveal-options='{"direction":"tb","bgcolor":"rgb(70, 70, 70)"}'>
                                    <div class="liquid-img-group-img-container">
                                        <div class="liquid-img-group-content content-fixed-left">
                                            <p>Next-generation development.</p>
                                        </div><!-- /.liquid-img-group-content -->
                                        <div class="liquid-img-container-inner">
                                            <figure>
                                                <img src="/assets/img/actual/chill.jpg" alt="Next-generation theme" />
                                            </figure>
                                        </div><!-- /.liquid-img-container-inner -->
                                    </div><!-- /.liquid-img-group-img-container -->
                                </div><!-- /.liquid-img-group-single -->
                            </div><!-- /.liquid-img-group-inner -->
                        </div><!-- /.liquid-img-group-container -->

                    </div><!-- /.lqd-column col-md-5 -->

                    <div class="lqd-column col-md-6 col-md-offset-1 col-xs-12">

                        <div class="ld-fancy-heading mb-30">
                            <h2 class="text-white" data-text-rotator="true">
									<span class="ld-fh-txt">
										Building products for
										<span class="txt-rotate-keywords">
											<span class="keyword active">business</span>
											<span class="keyword">agency</span>
											<span class="keyword">portfolio</span>
										</span>
									</span>
                            </h2>
                        </div><!-- /.ld-fancy-heading -->

                        <div class="row">

                            <div class="lqd-column col-sm-6">

                                <ul class="reset-ul text-fade-white-08 ltr-sp-025">
                                    <li class="mb-3">Web Development</li>
                                    <li class="mb-3">Mobile Development</li>
                                    <li class="mb-3">API Development</li>
                                </ul>

                            </div><!-- /.lqd-column col-sm-6 -->

                            <div class="lqd-column col-sm-6">

                                <ul class="reset-ul text-fade-white-08 ltr-sp-025">
                                    <li class="mb-3">Branding</li>
                                    <li class="mb-3">UI/UX Designs</li>
                                    <li class="mb-3">Print Designs</li>
                                </ul>

                            </div><!-- /.lqd-column col-sm-6 -->

                        </div><!-- /.row -->

                    </div><!-- /.lqd-column col-md-6 col-md-offset-1 -->

                </div><!-- /.row -->
            </div><!-- /.container -->
        </section>

        <!--<section class="vc_row pt-75 pb-75">
            <div class="container">
                <div class="lqd-column">
                    <h6 class="font-size-12 text-uppercase ltr-sp-2 text-purple">What our clients say</h6>

                    <hr class="w-10 m-0">
                    <h3 class="text-uppercase ltr-sp-2">Testimonials</h3>
                </div>
                <div class="row">

                    <div class="lqd-column col-md-6">

                        <div class="testimonial testimonial-whole-filled testimonial-whole-shadowed text-left">

                            <div class="testimonial-quote">
                                <blockquote>
                                    <p class="font-size-18 lh-2">“Not in a million years did I expect you to read my mind, but you did. Thank you for everything – you have really created a fabulous image for our company.”</p>
                                </blockquote>
                            </div>&lt;!&ndash; /.testimonial-qoute &ndash;&gt;

                            <div class="testimonial-details">
                                <div class="testimonial-info">
                                    <h5>Caleb Cruz</h5>
                                    <h6 class="font-weight-normal">Apple marketing manager</h6>
                                </div>&lt;!&ndash; /.testimonial-info &ndash;&gt;

                            </div>&lt;!&ndash; /.testimonial-details &ndash;&gt;

                        </div>&lt;!&ndash; /.testimonial &ndash;&gt;

                    </div>&lt;!&ndash; /.lqd-column col-md-6 &ndash;&gt;

                    <div class="lqd-column col-md-6">

                        <div class="testimonial testimonial-whole-filled testimonial-whole-shadowed text-left">

                            <div class="testimonial-quote">
                                <blockquote>
                                    <p class="font-size-18 lh-2">“Not in a million years did I expect you to read my mind, but you did. Thank you for everything – you have really created a fabulous image for our company.”</p>
                                </blockquote>
                            </div>&lt;!&ndash; /.testimonial-qoute &ndash;&gt;

                            <div class="testimonial-details">
                                <div class="testimonial-info">
                                    <h5>Caleb Cruz</h5>
                                    <h6 class="font-weight-normal">Apple marketing manager</h6>
                                </div>&lt;!&ndash; /.testimonial-info &ndash;&gt;

                            </div>&lt;!&ndash; /.testimonial-details &ndash;&gt;

                        </div>&lt;!&ndash; /.testimonial &ndash;&gt;

                    </div>&lt;!&ndash; /.lqd-column col-md-6 &ndash;&gt;

                </div>&lt;!&ndash; /.row &ndash;&gt;
            </div>&lt;!&ndash; /.container &ndash;&gt;
        </section>-->

        <section class="vc_row pt-50 pb-100">
            <div class="container">
                <div class="row">

                    <div class="lqd-column col-md-10 col-md-offset-1 text-center" data-custom-animations="true"
                         data-ca-options='{"triggerHandler":"inview","animationTarget":"all-childs","duration":1200,"delay":100,"initValues":{"translateY":80,"opacity":0},"animations":{"translateY":0,"opacity":1}}'>

                        <header class="fancy-title fancy-title-big mb-4">
                            <h2 data-split-text="true" data-split-options='{"type":"lines"}'>
                                Ready to<br>
                                <span class="font-size-2-15x lh-15 text-purple" data-fittext="true" data-fittext-options='{ "maxFontSize": 70, "minFontSize": 40, "compressor": 0.5 }'>Create?</span>
                            </h2>
                        </header><!-- /.fancy-title -->

                        <a href="/contact-us"
                           class="btn btn-purple text-uppercase circle btn-bordered border-thin font-size-14 font-weight-semibold"
                           data-localscroll="true" data-localscroll-options='{"scrollBelowSection":true}'>
                            <span>
                              <span class="btn-txt">Start a project</span>
                            </span>
                        </a>

                    </div><!-- /.col-md-10 col-md-offset-1 -->

                </div><!-- /.row -->
            </div><!-- /.container -->
        </section>
    </main>
</template>

<script>
    import { DynamicAssets } from "../../utils/DynamicAssets";

    export default {
        name: 'Home',
        mounted() {

            DynamicAssets.removeScriptsPage();
            DynamicAssets.loadScriptPage()
        }
    }
</script>
